import React from 'react';

import styled from 'styled-components';

import {
  NewButton,
  NewButtonTypes,
  NewButtonSizes,
} from '../../../components/Toolkit/NewButton/NewButton';
import { safeGet } from '../../../helpers/utils';

import { RADIUS_FILTER_VALUES, RadiusFilterProps, setRadius } from './';

const Wrapper = styled.div`
  padding: 38px 16px 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  color: #333333;
  width: 100%;
  @media only screen and (min-width: 1056px) {
    margin-top: 32px;
    text-align: left;
    border: 1px solid #dddddd;
    padding: 30px 20px;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  @media only screen and (min-width: 1056px) {
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-start;
    width: 100%;
  }
`;

const Heading = styled.h3`
  font-size: 14px;
  padding-bottom: 8px;
  @media only screen and (min-width: 1056px) {
    font-size: 16px;
    margin-bottom: 16px;
    line-height: 19px;
  }
`;

const Paragraph = styled.p`
  padding-bottom: 8px;
  @media only screen and (min-width: 1056px) {
    font-size: 16px;
  }
`;

const Options = styled.div`
  @media only screen and (min-width: 1056px) {
    justify-content: center;
    padding-left: 16px;
    button:last-of-type {
      margin: 0;
    }
  }
`;

const QuickLinkRadiusFilter = (props: RadiusFilterProps) => {
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    const target = event.currentTarget as HTMLButtonElement;
    setRadius(parseInt(target.value, 10), props);
    window.scrollTo(0, 0);
  };
  const radius = safeGet(props.locationFilterCurrentValue, ['radius'], 0);
  return !radius ||
    parseInt(RADIUS_FILTER_VALUES[RADIUS_FILTER_VALUES.length - 1].value, 10) >
      radius ? (
    <Wrapper data-testid="radius-quicklinks">
      <Heading>Didn't find what you were looking for?</Heading>
      <Content>
        <Paragraph>Expand your search:</Paragraph>
        <Options>
          {RADIUS_FILTER_VALUES.map(
            (option: {
              valueType: string;
              value: string;
              displayName: string;
            }) => {
              if (
                !safeGet(props, ['locationFilterCurrentValue', 'radius'], 0) ||
                radius < parseInt(option.value, 10)
              ) {
                return (
                  <NewButton
                    key={option.displayName}
                    buttonText={option.displayName}
                    buttonType={NewButtonTypes.TERTIARY}
                    buttonSize={NewButtonSizes.X_SMALL}
                    onClick={handleClick}
                    value={option.value}
                    data-testid={`radius-quicklink-${option.value}`}
                  />
                );
              }
            },
          )}
        </Options>
      </Content>
    </Wrapper>
  ) : null;
};

export default QuickLinkRadiusFilter;
