export const RADIUS_FILTER_VALUES = [
  { valueType: 'FilterStringValue', value: '1000', displayName: '+1km' },
  { valueType: 'FilterStringValue', value: '3000', displayName: '+3km' },
  { valueType: 'FilterStringValue', value: '5000', displayName: '+5km' },
  { valueType: 'FilterStringValue', value: '10000', displayName: '+10km' },
  { valueType: 'FilterStringValue', value: '20000', displayName: '+20km' },
];

export type LocationValue = { location: string[]; radius: string };

export type RadiusFilterProps = {
  onChange: (query: any) => void;
  locationFilterCurrentValue?: LocationValue;
  setRadius?: (val: number, props: RadiusFilterProps) => void;
  inputFocus?: boolean;
};

export const setRadius = (val: number, props: RadiusFilterProps) => {
  const { locationFilterCurrentValue = { radius: 0 } } = props;
  locationFilterCurrentValue.radius = `${val}`;
  props.onChange({
    filterType: 'CountyArea',
    name: 'location',
    SearchQueryObject: 'geoFilter',
    geoSearchType: 'STORED_SHAPES',
    currentValue: locationFilterCurrentValue,
  });
};

export {
  MobileRadiusFilter,
  DesktopRadiusFilter,
} from './DefaultRadiusFilters';
export { default as QuickLinksRadiusFilter } from './QuickLinksRadiusFilter';
