import React from 'react';

import styled from 'styled-components';

import { safeGet } from '../../../helpers/utils';

import { RADIUS_FILTER_VALUES, RadiusFilterProps, setRadius } from './';
import DefaultSingleSelectFilter from '../../Filters/SingleSelect/DefaultSingleSelect';
import SecondarySingleSelect from '../../Filters/SingleSelect/SecondarySingleSelect';

const handleChange = (
  radiusObject: { currentValue: { values: string[] } },
  props: RadiusFilterProps,
) => {
  const radius = safeGet(radiusObject, ['currentValue', 'values', 0], '');
  setRadius(radius, props);
};

const StyledDesktopRadiusFilter = styled(SecondarySingleSelect)`
  font-weight: 600;
  height: 46px;
  display: ${({ inFocus }) => inFocus && 'none'};
  opacity: ${({ active }) => (active ? 1 : 0.4)};
  cursor: ${({ active }) => !active && 'auto'};

  @media only screen and (min-width: 704px) {
    height: 61px;
  }

  &:hover {
    text-decoration: none;
  }
`;

export const DesktopRadiusFilter = (props: RadiusFilterProps) => (
  <StyledDesktopRadiusFilter
    dataTestId="radius-filter-desktop"
    active={
      safeGet(props.locationFilterCurrentValue, ['location'], []).length ===
        1 && props.locationFilterCurrentValue?.location[0] !== 'mapArea'
    }
    inFocus={props.inputFocus}
    {...{
      searchQueryGroup: 'none',
      name: 'radius',
      displayName: 'Radius',
      currentValue: {
        values: [
          props.locationFilterCurrentValue &&
            props.locationFilterCurrentValue.radius,
        ],
      },
      values: [
        { valueType: 'FilterStringValue', value: '', displayName: '+0km' },
      ].concat(RADIUS_FILTER_VALUES),
      alternativeStyle: false,
      showLabel: false,
      key: 'manual_filter_desktop',
      onChange: (values: any) => handleChange(values, props),
      disabled:
        (props.locationFilterCurrentValue &&
          safeGet(props.locationFilterCurrentValue, ['location'], []).length !==
            1) ||
        props.locationFilterCurrentValue?.location[0] === 'mapArea',
    }}
  />
);

export const MobileRadiusFilter = (props: RadiusFilterProps) => (
  <DefaultSingleSelectFilter
    {...{
      searchQueryGroup: 'none',
      name: 'radius',
      displayName: 'Radius',
      currentValue: {
        values: [
          props.locationFilterCurrentValue &&
            props.locationFilterCurrentValue.radius,
        ],
      },
      values: [
        {
          valueType: 'FilterStringValue',
          value: '',
          displayName: 'This location only',
        },
      ].concat(RADIUS_FILTER_VALUES),
      id: 'radius',
      alternativeStyle: false,
      showLabel: true,
      key: 'manual_filter_mobile',
      onChange: (values: any) => handleChange(values, props),
      dataTestId: 'radius-filter-mobile',
    }}
  />
);
