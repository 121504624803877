import React, { Component } from 'react';

import MultiDownshift from './MultiDownshift';
import { AreaTag } from '../../types';

type MultiInputTextSelectProps = {
  id: string;
  placeHolderText: string;
  onSelectionChange?: (selectedItems: AreaTag[]) => void;
  currentValue?: AreaTag[];
  minChars?: number;
  items: AreaTag[];
  onInputChange?: (userInput: string) => void;
  cleanup?: () => void;
  isFocused?: boolean;
  children?: any;
};

export class MultiInputTextSelect extends Component<MultiInputTextSelectProps> {
  state = {
    selectedItems: this.props.currentValue || [],
  };

  componentDidUpdate(prevProps: MultiInputTextSelectProps) {
    if (this.props.currentValue !== prevProps.currentValue) {
      this.setState({
        selectedItems: this.props.currentValue,
      });
    }
  }

  componentWillUnmount() {
    this.props.cleanup && this.props.cleanup();
  }

  onInputValueChange = (inputValue: string) => {
    this.props.onInputChange && this.props.onInputChange(inputValue || '');
  };

  onItemAdd = (selectedItem: AreaTag) => {
    if (!selectedItem) return;
    this.setState(
      {
        selectedItems: [...this.state.selectedItems, selectedItem],
      },
      () =>
        this.props.onSelectionChange &&
        this.props.onSelectionChange(this.state.selectedItems),
    );
  };

  onRemoveItem = (item: AreaTag & { index: number }) => {
    if (!item || item.index < 0) {
      return;
    }

    const copy = [...this.state.selectedItems];
    copy.splice(item.index, 1);
    this.setState(
      {
        selectedItems: copy,
      },
      () =>
        this.props.onSelectionChange &&
        this.props.onSelectionChange(this.state.selectedItems),
    );
  };

  itemToString(item: AreaTag) {
    return item ? item.displayName : '';
  }

  render() {
    return (
      <MultiDownshift
        id={this.props.id}
        selectedItem={this.state.selectedItems}
        onChange={this.onItemAdd}
        onRemoveItem={this.onRemoveItem}
        items={this.props.items}
        itemToString={this.itemToString}
        placeHolderText={this.props.placeHolderText}
        onInputValueChange={this.onInputValueChange}
        isFocused={this.props.isFocused}
      >
        {this.props.children}
      </MultiDownshift>
    );
  }
}
