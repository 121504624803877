import React, { Component } from 'react';

import { authorise } from '../../helpers/auth/auth';
import { callAll, debounce } from '../../helpers/utils';

export class AutocompleteConnectionHelper extends Component<
  {
    children: any;
    minChars?: number;
    path?: string;
    apiCall: any;
    authorized?: boolean;
  },
  { items: any[]; requestsLoading: number }
> {
  state = { items: [], requestsLoading: 0 };
  locations = [];

  forceAuthenticatedUser = async () => {
    const token = await authorise(
      {
        forceLogIn: false,
        redirectBackTo: '/',
      },
      'daft',
    );
    return token;
  };

  debouncedRequest = debounce(async (input: string, path: string) => {
    let validToken = null;
    if (this.props.authorized) {
      validToken = await this.forceAuthenticatedUser();
    }
    this.setState((prevState) => ({
      requestsLoading: prevState.requestsLoading + 1,
    }));

    const searchResults = await this.props.apiCall({
      text: input,
      path,
      token: validToken,
    });

    this.setState((prevState) => ({
      items: searchResults,
      requestsLoading: Math.max(0, prevState.requestsLoading - 1),
    }));
  }, 290);

  setIsLoading = (val: 1 | -1) => {
    this.setState((prevState) => ({
      requestsLoading: Math.max(prevState.requestsLoading + val, 0),
    }));
  };

  onInputChange = (input: string) => {
    const { minChars = 3, path = '' } = this.props;
    if (input && input.length >= minChars) {
      this.debouncedRequest(input, path);
    } else {
      this.setState({ items: [] });
    }
  };

  getProps = ({ onInputChange, cleanup, ...additionalProps }: any) => ({
    ...additionalProps,
    isLoading: this.state.requestsLoading > 0,
    items: this.state.items,
    onInputChange: callAll(onInputChange, this.onInputChange),
    setIsLoading: this.setIsLoading,
  });

  render() {
    return <>{this.props.children({ getAutocompleteProps: this.getProps })}</>;
  }
}
