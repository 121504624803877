import React from 'react';

import styled from 'styled-components';

import { SingleSelectFilter } from '../components-filter/SingleSelect/SingleSelectFilter';
import { CustomSelectSimple } from '../Styles/SharedFilterStyles';

const StyledSecondarySingleSelectFilter = styled(SingleSelectFilter)`
  appearance: none;
  border: none;
  background-color: transparent;
  padding-left: 8px;
  padding-right: 24px;
  font-size: 16px;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }

  @media only screen and (min-width: 704px) {
    padding-right: 36px;
    font-size: 14px;
  }
`;

const SecondarySingleSelect = styled.div`
  display: flex;
  align-items: center;
`;

const Label = styled.label`
  font-size: 16px;
  @media only screen and (min-width: 704px) {
    font-size: 14px;
  }
`;

const SecondarySingleSelectFilter = (props: any) => (
  <SecondarySingleSelect>
    {props.showLabel && (
      <Label htmlFor={props.name}>{props.displayName}: </Label>
    )}
    <CustomSelectSimple>
      <StyledSecondarySingleSelectFilter {...props} />
    </CustomSelectSimple>
  </SecondarySingleSelect>
);

export default SecondarySingleSelectFilter;
